// Base imports
import Vue from "vue";
import CompositionApi from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";

import "./registerServiceWorker";
import "buefy/dist/buefy.css";
import "vue-tour/dist/vue-tour.css";
import "./App.css";
import "./filters/main";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "vue-swatches/dist/vue-swatches.css";

// Component imports
import VueApexCharts from "vue-apexcharts";
import PortalVue from "portal-vue";
import vueKanban from "vue-kanban";
import { RecycleScroller, DynamicScroller } from "vue-virtual-scroller";
import VirtualizedList from "vue-virtualized-list";
import MoneyInput from "./modules/Global/components/MoneyInput";
import PercentageInput from "./modules/Global/components/PercentageInput";
import MoneyInputBlur from "./modules/Global/components/MoneyInputBlur";
import UnitInput from "./modules/Global/components/UnitInput";
import VueTour from "vue-tour";
import VueSignaturePad from "vue-signature-pad";
import IdleVue from "idle-vue";

const eventsHub = new Vue();

// Add needed components
Vue.component("virtualized-list", VirtualizedList);
Vue.component("RecycleScroller", RecycleScroller);
Vue.component("DynamicScroller", DynamicScroller);
Vue.component("MoneyInput", MoneyInput);
Vue.component("PercentageInput", PercentageInput);
Vue.component("MoneyInputBlur", MoneyInputBlur);
Vue.component("UnitInput", UnitInput);
Vue.component("apexchart", VueApexCharts);

// Use external modules
Vue.use(vueKanban);
Vue.use(Buefy);
Vue.use(VueTour);
Vue.use(PortalVue);
Vue.use(CompositionApi);
Vue.use(VueSignaturePad);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 86400000, // 15 minutes
  startAtIdle: false,
});

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === "production" ? false : true;

// Permissions global mixing
Vue.mixin({
  methods: {
    /**
     *
     * @desc checks if a user can access a specific resource or route
     * @param module the name of the module the user is accessing
     * @param resource the name of the resource in the module the user is accessing
     * @returns {boolean} return true if the user has permissions or false in the other case
     */
    checkPermissions(module, resource) {
      return (
        this.userPermissions.permissions[module][resource] &&
        this.userPermissions.permissions[module][resource] === "S"
      );
    },
    checkIfValid(data) {
      return data !== undefined && data !== null && data !== "";
    },
    /**
     *
     * @desc checks if the company can issue an invoice to a client
     * @param {*} clientId
     */
    checkIfCanInvoice(clientId) {
      let found = false;

      // Validate client information
      for (const singleClient of this.clients) {
        if (clientId === singleClient._id) {
          found = true;
          if (!singleClient.CP_CLI || !singleClient.RFC_CLI) {
            return false;
          }
        }
      }

      // Check that client was found
      if (!found) {
        return false;
      }

      // Check company information
      if (
        this.companyInformation.NO_FACTURACION === "S" ||
        !this.companyInformation.NOMBRE_FAC ||
        !this.companyInformation.CERTIFICADO_SAT ||
        !this.companyInformation.LLAVE_SAT ||
        !this.companyInformation.CLAVE_LLAVE ||
        !this.companyInformation.CLAVE_REGIMEN ||
        !this.companyInformation.CP
      ) {
        return false;
      }

      return true;
    },
    /**
     *
     * @desc checks if the company can issue an invoice
     */
    checkIfCanInvoiceCompany() {
      // Check company information
      if (
        this.companyInformation.NO_FACTURACION === "S" ||
        !this.companyInformation.NOMBRE_FAC ||
        !this.companyInformation.CERTIFICADO_SAT ||
        !this.companyInformation.LLAVE_SAT ||
        !this.companyInformation.CLAVE_LLAVE ||
        !this.companyInformation.CLAVE_REGIMEN ||
        !this.companyInformation.CP
      ) {
        return false;
      }

      return true;
    },
    /**
     *
     * @desc checks if user is accesing via cellphone
     */
    isMobileNavigator() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    saveColumnsToLocalStorage(columns, storageKey) {
      const columnsState = columns.map((column) => ({
        field: column.field,
        display: column.display,
      }));
      localStorage.setItem(storageKey, JSON.stringify(columnsState));
    },
    loadColumnsFromLocalStorage(columns, storageKey) {
      const savedColumns = localStorage.getItem(storageKey);
      if (savedColumns) {
        const parsedColumns = JSON.parse(savedColumns);
        return columns.map((column) => {
          const savedColumn = parsedColumns.find(
            (sc) => sc.field === column.field
          );
          return {
            ...column,
            display: savedColumn ? savedColumn.display : column.display,
          };
        });
      }
      return columns;
    },
  },
  computed: {
    // The current user logged in permissions
    userPermissions() {
      return this.$store.getters.USER.permissions;
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
});

// Initialize vue applications
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

let state = {
  // Current company information
  companyInformation: {
    _id: null,
    name: null,
    NO_FACTURACION: null,
    paymentPlan: null,
    NOMBRE_FAC: null,
    CERTIFICADO_SAT: null,
    LLAVE_SAT: null,
    CLAVE_LLAVE: null,
    CLAVE_REGIMEN: null,
    RFC: null,
    CP: null,
    DOMICIL: null,
    REFERENCIA: null,
    NUM_EXTER: null,
    NUM_INTER: null,
    NOMBRE_MUNICIPIO: null,
    MUNICIPIO: null,
    ESTADO: null,
    LOCALIDAD: null,
    COLONIA: null,
    NOMBRE_COLONIA: null,
    TELS: null,
    CEL: null,
    PATER: null,
    MATER: null,
    NOMBRE: null,
    EMAIL: null,
    EMAIL_ERRORES_FACTURACION: null,
    suscribedPayment: false,
    FECHA_COBRO: null,
    FECHA_DE_VENCIMIENTO: null,
    PLAN_PAGO: null,
    STATUS: false,
    ACTUALIZAR_LINK: false,
    availableMonthlyStamps: 0,
    availableStamps: 0,
    MOVIMIENTO_OBLIGATORIO: false,
    ENVIAR_MATERIAL_VENTA: false,
    PERMITIR_ENVIO_SIN_PAGO: false,
    AUTOCOMPLETAR_CODIGO_ALEATORIO: false,
    NO_VENDER_CUENTAS_CLIENTE: false,
    CONSOLIDAR_SALDO_AUTOMATICAMNTE: false,
    MOVIMIENTO_MANUAL_POS_TARJETAS: false,
    POR_SUP_REVISION_COMPRAS: 0,
    POR_INF_REVISION_COMPRAS: 0,
    LISTA_PRECIOS_PRE: "",
    FONDO: "",
    FONDO_SECUNDARIO: "",
    TEXTO: "",
    TEXTO_SECUNDARIO: "",
    LEYENDA_SUPERIOR: "",
    LEYENDA_INFERIOR: "",
    NOT_COMPRAS: [],
    NOT_COMPRAS_CON: [],
    NOT_VENTAS: [],
    LOGO_ID: "",
    LOGO_URL: "",
  },
  nextPaymentDate: false,
  //User already logged in
  loggedIn: false,
  //Data already loaded to the store, used for router validation
  loaded: false,
  //Display loading screen
  loading: false,
  //Loding message
  loadingMessage: "Cargando...",
  //Url where the backend is located
  backenUrl: "",
  //User logged in information, control information
  user: {
    name: null,
    email: null,
    permissions: null,
    token: null,
    companyMainName: null,
    validatedEmail: false,
    tourCompleted: false,
    betaMessageCompleted: false,
    ALMACENES: [],
  },
  notifications: [],
  //Sat link
  satLink: "",
  //Company KPIS
  kpis: {
    sales: {
      total: 0,
      quantity: 0,
      average: 0,
    },
    quotes: {
      total: 0,
      quantity: 0,
      average: 0,
    },
    purchases: {
      total: 0,
      quantity: 0,
      average: 0,
    },
    orders: {
      total: 0,
      quantity: 0,
      average: 0,
    },
    expensesPurchases: {
      total: 0,
      quantity: 0,
    },
    expensesOther: {
      total: 0,
      quantity: 0,
    },
    incomeSales: {
      total: 0,
      quantity: 0,
    },
    incomeOther: {
      total: 0,
      quantity: 0,
    },
  },
  graphs: {
    sales: [],
    quotes: [],
    purchases: [],
    orders: [],
    expensesPurchases: [],
    expensesOther: [],
    incomeSales: [],
    incomeOther: [],
  },
  lists: {
    mostSoldArticles: [],
    mostBoughtArticles: [],
  },
  measures: [],
  companyTotalValue: {
    inventory: 0,
    expenses: 0,
    incomes: 0,
    banks: 0,
    company: 0,
  },
  companyNames: [],
  causeCodes: [],
};

export default state;
